import React from 'react';
import { motion } from 'framer-motion';
// 💡🛸
const AnimatedEmojis = ({ emoji = '💡' }) => {
  return (
    <motion.div 
      animate={{ 
        rotate: [0, 3, -3, 0], // Subtle wobble
        scale: [2, 2.1, 1.9, 2], // Slight breathing effect
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        ease: "easeInOut"
      }}
      className="text-[20rem] inline-block" // Using custom size larger than Tailwind's largest class
    >
      {emoji}
    </motion.div>
  );
};

export default AnimatedEmojis;