import React from 'react'; 
import { ChakraProvider, Box, Container, extendTheme, Button, Flex, Text} from '@chakra-ui/react'; 
import { motion } from 'framer-motion'; 
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; 
import Navbar from './Navbar'; 
import MyIntro from './MyIntro'; 
import Me from './AboutMe'; 
import Projects from './Projects'; 
import Projects2 from './Projects2';
import { Analytics } from '@vercel/analytics/react'; 
import { useNavigate } from 'react-router-dom'; 
import AnimatedBackground from './AnimatedBackground';  

//  #1b1816 (darker background)
//  #241f1c  #937047  #e7dac7 
//  #ebebde  #777764  #4f4747

const customTheme = extendTheme({
  fonts: {
    heading: "'Open Sans', sans-serif",
    body: "'Open Sans', sans-serif",
  },
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
  },
  styles: {
    global: {
      body: {
        backgroundSize: '400% 400%',
        minHeight: '100vh',
        backgroundColor: '#1b1816', //  navy peone: '#223a5e',
        color: '#937047' 
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        color: '#e7dac7',
        _hover: {
          bg: 'rgba(147, 112, 71, 0.5)', 
          color: '#e7dac7', 
          borderColor: '#937047', 
        },
      }
    },
    Text: {
      baseStyle: {
        color: '#937047'
      }
    },
    Heading: {
      baseStyle: {
        color: '#937047'
      }
    }
  }
});

const MotionBox = motion(Box);



const HomePage = () => {
  return (
    <Flex justify="center" align="center" height="75vh" direction="column">
      <MyIntro />
      <Flex justify="center" align="center" direction="row">
        <Button 
          as={Link} 
          to="/about" 
          mr={4}
          variant="outline"
          borderColor="#937047"
          color="#e7dac7"
          _hover={{
            bg: 'rgba(147, 112, 71, 0.5)',
            color: '#e7dac7',
            borderColor: '#937047',
          }}
        >
          About Me
        </Button>
        <Button 
          as={Link} 
          to="/projects"
          variant="outline"
          borderColor="#937047"
          color="#e7dac7"
          _hover={{
            bg: 'rgba(147, 112, 71, 0.5)',
            color: '#e7dac7',
            borderColor: '#937047',
          }}
        >
          Projects
        </Button>
      </Flex>
    </Flex>
  );
};

const AboutPage = () => {
  const navigate = useNavigate();
  return (
    <Flex justify="center" align="center" height="100vh" direction="column">
      <Me />
      <Button 
        onClick={() => navigate(-1)} 
        m={2}
        variant="outline"
        borderColor="#937047"
        _hover={{
          bg: 'rgba(147, 112, 71, 0.5)',
          color: '#e7dac7',
          borderColor: '#937047',
        }}
      >
        Home
      </Button>
    </Flex>
  );
};

const ProjectsPage = () => {
  const navigate = useNavigate();
  return (
    <Flex justify="center" align="center" height="100vh" direction="column">
      <Projects2 />
      <Button 
        onClick={() => navigate(-1)} 
        m={2}
        variant="outline"
        borderColor="#937047"
        _hover={{
          bg: 'rgba(147, 112, 71, 0.5)',
          color: '#e7dac7',
          borderColor: '#937047',
        }}
      >
        Home
      </Button>
    </Flex>
  );
};

const App = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 5 }}
        >
          <Box p={{ base: "20px", sm: "30px", md: "50px" }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/projects" element={<ProjectsPage />} />
            </Routes>
          </Box>
        </MotionBox>
        <Analytics />
      </Router>
    </ChakraProvider>
  );
};

export default App;