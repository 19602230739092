import Project from './Project';
import { ReactElement } from 'react';
import {
  Image, Code, Link, Box, SimpleGrid, Icon, Text, Stack, Flex, Heading
} from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit, FcOk } from 'react-icons/fc';
import { useColorMode } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

const Feature = ({ url, title, text, lnk, l1, l2, l3 }: FeatureProps) => {
  const color = "#e7dac7";
  
  return (
    <Stack 
      spacing={2}
      p={4} 
      borderWidth={1} 
      borderRadius="md" 
      borderColor="#937047"  // Explicitly setting border color
      bg="rgba(0,0,0,0.1)"
      height="100%"
      minH="130px"  // Reduced from 200px
    >
      <Box> 
        <Flex justifyContent="space-between" alignItems="center" mb={2}>
          <Text color={color} fontWeight={600}>
            <Link isExternal href={lnk}>
              {title} <ExternalLinkIcon mx='2px' />
            </Link>
          </Text>
        </Flex>
        
        <Text color={color} fontSize="sm" mb={2}>
          {text}
        </Text>
      </Box>
      
      <Flex alignItems="center" gap={2}>
        <Text color="#e7dac7" fontSize={12} fontWeight="bold">STACK:</Text>
        <Code bg="rgba(211, 211, 211, 0.4)" color="#e7dac7" fontSize="xs">{l1}</Code>
        <Code bg="rgba(211, 211, 211, 0.4)" color="#e7dac7" fontSize="xs">{l2}</Code>
        <Code bg="rgba(211, 211, 211, 0.4)" color="#e7dac7" fontSize="xs">{l3}</Code>
      </Flex>
    </Stack>
  );
};

export default function Projects() {
  return (
    <Box
      id="Projects"
      px={{ base: 4, md: 20 }}
      py={4}  // Reduced from py={6}
    >
      <Heading
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign="center"
        mb={4}  // Reduced from mb={6}
        color={"#e7dac7"}
      >
        Projects
      </Heading>
      
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={3}  // Reduced from spacing={4}
        justifyItems="stretch"
        alignItems="stretch"
      >
        <Feature
          title={'cryptaid'}
          text={'Platform for secure, anonymous donations to organizations using Ethereum'}
          lnk={"https://cryptaid-theta.vercel.app/"}
          l1={"Solidity"}
          l2={"React"}
          l3={"Moralis SDK"}
          url={require('./images/cryptaid.png')}
        />
        
        <Feature
          title={'30Seconds'}
          text={'Website for behavioral interview practice, providing feedback on self-recorded interviews'}
          lnk={"https://seconds-ef259.web.app/"}
          l1={"HTML/CSS"}
          l2={"React"}
          l3={"AssemblyAI API"}
          url={require('./images/30s.png')}
        />
        
        <Feature
          title={'Fiesta'}
          text={'Social platform for discovering, rating, and reviewing parties on a map'}
          lnk={"https://fiesta-5caa1.web.app/"}
          l1={"Firebase"}
          l2={"React"}
          l3={"Google Maps API"}
          url={require('./images/fiesta.png')}
        />
        
        <Feature
          title={'InstaAid'}
          text={'Health app to connect users with friends or family during an epipen or inhaler emergency'}
          lnk={"https://devpost.com/software/instaaid-3rmdzf"}
          l1={"Swift/Xcode"}
          l2={"Node.js"}
          l3={"Sketch"}
          url={require('./images/instaaid.png')}
        />
      </SimpleGrid>
    </Box>
  );
}