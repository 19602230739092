import Project from './Project';
import { ReactElement } from 'react';
import { Image, Link, useBreakpointValue, Box, SimpleGrid, Icon, Text, HStack,Stack, Flex, Heading, Spacer, Button } from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';
import Highlighter from "react-highlight-words";
import Emoji from './Emoji';
import { useColorMode, useColorModeValue} from '@chakra-ui/react';

import AnimatedEmojis from './AnimatedEmojis';

export default function MyIntro() {

  const { toggleColorMode } = useColorMode();
  const color = 'white';
  const linked_in_white = require('./images/linkedin.svg').default;
  const linked_in_black = require('./images/linkedin_black.svg').default;
  const linkedInImage = useColorModeValue(linked_in_white, linked_in_white);
  
  const email_white = require('./images/email.svg').default;
  const email_black = require('./images/email_black.svg').default;
  const email = useColorModeValue(email_white, email_white);

  const github_white = require('./images/github_white.svg').default;
  const github_black = require('./images/github.svg').default;
  // const github = useColorModeValue(github_black, github_white);
  const github = github_white
  
  const cv_white = require('./images/cv_white.svg').default;
  const cv_black = require('./images/cv.svg').default;
  const cv = useColorModeValue(cv_black, cv_white);

  return (
    <Box 
      id="MyIntro"
      margin={{ base: 2, md: 10 }} 
      paddingTop={{ sm: 40, md: 50, lg: 100 }}
      pr={{ base: 6, md: 100 }}
      pl={{ base: 6, md: 100 }}
    >
      {/* Flex container for centering */}
      <Flex justify="center" align="center" direction="column">
        <Stack spacing={2} w={'full'} maxW={'lg'} textAlign="center">
        <AnimatedEmojis></AnimatedEmojis>
          <Heading size='3xl'>
            <Emoji symbol="👨🏻‍💻" />
          </Heading>

          <Heading fontSize={{ base: '3xl', md: '3.5xl', lg: '4xl' }}>
            <Text fontSize={'111%'} as={'span'} position={'relative'}>
              Jacey Buchner
            </Text>
          </Heading>

          <Text color={"#e7dac7"} fontSize={{ base: 'medium', lg: 'xl' }}>
            Recent UCSB Comp Sci Alum <Emoji symbol="🌴" />
          </Text>

          <HStack spacing={3} justify="center">
            <Link isExternal href="https://www.linkedin.com/in/jaceybuchner/">
              <Image
                height={'4vh'}
                mb={'2'}
                maxW={"90%"}
                alt={'LinkedIn'}
                objectFit={'contain'}
                src={linkedInImage}
              />
            </Link>

            <Link isExternal style={{ textDecoration: 'none' }} href="mailto:jaceybuchner@gmail.com">
              <Image
                height={'4vh'}
                mb={'2'}
                maxW={"90%"}
                alt={'Email'}
                objectFit={'contain'}
                src={email}
              />
            </Link>
            
          </HStack>
    
        </Stack>
      </Flex>
    </Box>
  );
}
