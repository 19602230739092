import { Link, Box, Heading, Text, Flex, UnorderedList, ListItem } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';

export default function Me() {
  const color = "#e7dac7";
  
  return (
    <Box
      id="About"
      px={{ base: 4, md: 20 }}
      py={4}
    >
      <Heading
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign="center"
        mb={4}
        color={color}
      >
        About Me:
      </Heading>
      
      <Box
        borderWidth={1}
        borderRadius="md"
        borderColor="#937047"  // Explicitly setting border color
        bg="rgba(0,0,0,0.1)"
        p={4}
        maxW="3xl"
        mx="auto"
      >
        <UnorderedList
          spacing={3}
          listStyleType="none"
          color={color}
          pl={0}
          fontSize="lg"
          m={0}
        >
          <ListItem mb={2}>
            Previous Intern at Google Cloud and Google Ads
          </ListItem>
          
          <ListItem mb={2}>
            Undergrad Researcher for the{' '}
            <Link
              href="https://seclab.cs.ucsb.edu/"
              isExternal
              color="#937047"
              _hover={{ color: color }}
            >
              SecLab@UCSB 
            </Link>
          </ListItem>
          
          <ListItem mb={2}>
            Currently a Peer Mentor in the{' '}
            <Link
              href="https://inspire.engineering.ucsb.edu/meet-team"
              isExternal
              color="#937047"
              _hover={{ color: color }}
            >
              Inspire Program 
            </Link>
          </ListItem>
          
          <ListItem mb={2}>
            Formerly helped manage and operate{' '}
            <Link
              href="https://x.com/blockchainucsb?lang=en"
              isExternal
              color="#937047"
              _hover={{ color: color }}
            >
              Blockchain@SB 
            </Link>
          </ListItem>
          
          <ListItem>
            Product Management Intern at{' '}
            <Link
              href="https://healthy.kaiserpermanente.org/northern-california/front-door"
              isExternal
              color="#937047"
              _hover={{ color: color }}
            >
              Kaiser Permanente 
            </Link>
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
}