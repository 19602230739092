import React from 'react';
import { Box } from '@chakra-ui/react';

const AnimatedBackground = () => {
  // Navy Peony and complementary color palette
  const colors = [
    '#223a5e',  // Navy Peony (primary)
  ];

  // Generate a grid of rectangles that tessellate
  const generateRectangles = () => {
    const rectangles = [];
    const gridSize = 10; // Number of rectangles per row/column
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const maxRectWidth = viewportWidth / gridSize;
    const maxRectHeight = viewportHeight / gridSize;

    for (let row = 0; row < gridSize; row++) {
      for (let col = 0; col < gridSize; col++) {
        // Varying rectangle sizes with some overlap
        const widthVariation = Math.random() * 0.5 + 0.5; // 50-100% of max width
        const heightVariation = Math.random() * 0.5 + 0.5; // 50-100% of max height
        
        rectangles.push({
          x: col * maxRectWidth,
          y: row * maxRectHeight,
          width: maxRectWidth * widthVariation,
          height: maxRectHeight * heightVariation,
          color: colors[Math.floor(Math.random() * colors.length)],
          rotation: Math.random() * 10 - 5, // Slight random rotation (-10 to 10 degrees)
          opacity: Math.random() * 1  // 20-50% opacity
        });
      }
    }

    return rectangles;
  };

  const rectangles = generateRectangles();

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      zIndex="-1"
      overflow="hidden"
    >
      {rectangles.map((rect, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: `${rect.y}px`,
            left: `${rect.x}px`,
            width: `${rect.width}px`,
            height: `${rect.height}px`,
            backgroundColor: rect.color,
            opacity: rect.opacity,
            transform: `rotate(${rect.rotation}deg)`,
            borderRadius: '10px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.1)' // Subtle shadow for depth
          }}
        />
      ))}
    </Box>
  );
};

export default AnimatedBackground;